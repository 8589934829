/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable prefer-const */
/* eslint-disable comma-spacing */
/* eslint-disable quotes */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { ArrowLeftOutlined, RightCircleOutlined } from '@ant-design/icons';
import 'antd/dist/antd.dark.css';
import { Table, Tabs, notification, Tooltip, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import CrcPmTable from './CrcPmTable';
import CmCrcTable from './CmCrcTable';
import RawSummery from './RawSummery';

const { TabPane } = Tabs;
const { Title } = Typography;

const RawCards = () => {
    const history = useHistory();
    const [group, setGroup] = useState(false);
    const [currentSection, setCurrentSection] = useState('1');

    const onChange = (key) => {
        setCurrentSection(key);
    };

    return (
        <div className="h-100 d-flex flex-column">
            <div className="d-flex mb-2 align-items-center tab-content-title">
                <div className='back_equip' >
                    <Tooltip title={'Back'} >
                        <ArrowLeftOutlined onClick={(e) => {
                            history.push('/project-margin');
                        }} />
                    </Tooltip>
                </div>
                <Title className="mb-0 mt-1 add-new-calibration" level={3} style={{ paddingLeft: '30px' }}>
                    Raw Data
                </Title>
            </div>
            <div className="flex-fill">
                <Tabs
                    className="custom-tab h-100"
                    onChange={onChange}
                >
                    <TabPane tab="CRC PM FBL3N" key="1">
                        <CrcPmTable />
                    </TabPane>
                    <TabPane tab="CM CRC PM FBL3N" key="2">
                        <CmCrcTable />
                    </TabPane>
                    <TabPane tab="Project Summary Consol" key="3">
                        <RawSummery />
                    </TabPane>
                </Tabs>
            </div>
        </div>
    );
};

export default RawCards