/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unescaped-entities */
import { ArrowLeftOutlined, CloudUploadOutlined, DeleteOutlined, EditOutlined, EyeOutlined, FilterOutlined, RightCircleTwoTone, FilePdfOutlined, DownCircleTwoTone, RightCircleOutlined } from '@ant-design/icons';
import {
    Button, Form,
    Input,
    Modal,
    Space,
    Table,
    Tabs,
    Tooltip,
    Typography, Popover,
    Upload, notification, DatePicker, Drawer, Select, Layout, Row, Col, Card
} from 'antd';
// import { IonIcon } from "@ionic/react";
// import { closeOutline, cameraOutline, diamondOutline, chatbubbleOutline, alarmOutline, ellipsisHorizontal, ellipsisVertical, eyeOutline, createOutline, downloadOutline } from "ionicons/icons";
import { get } from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import history from '../../services/history';
import MasteForm from './MasteForm';


const { Option } = Select;
const { Title, Text } = Typography;
const { TabPane } = Tabs;
//const { TabPane } = Tabs;
//const { Column } = Table;
//const { Search } = Input;

//const { Option } = Select;
const FinaceMapping = (props) => {

    const {

    } = props;


    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isMasterModalVisible, setIsMasterModalVisible] = useState(false);
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [currentPagesize, setCurrentPagesize] = useState(10);

    const [userName, setUserName] = useState('');
    const [userGroup, setUserGroup] = useState([]);


    const showMasterModal = () => {
        setIsMasterModalVisible(true);
    }


    return (

        <div className="d-flex flex-column h-100">
            {!isMasterModalVisible && <div>
                <div className="flex-auto">
                    <div className="d-flex mb-2 align-items-center tab-content-title">
                        <div className='back_equip' >
                            <Tooltip title={'Back'} >
                                <ArrowLeftOutlined onClick={(e) => {
                                    history.push('/SmartLync-HomePage');
                                }} />
                            </Tooltip>
                        </div>
                        <Title className="mb-0 mt-1 add-new-calibration" level={3} style={{ paddingLeft: '30px' }}>
                            Finance
                        </Title>
                        <div className="new-calibration">
                            {
                                // userGroup && userGroup.includes('pricing-admin') &&
                                <Button shape="round" key="apply" type="primary" style={{ marginRight: '20px' }}
                                    onClick={showMasterModal}
                                >
                                    Configuration
                                </Button>
                            }
                            {/* <Button shape="round" key="apply" type="primary"
                                onClick={showDrawer}
                                style={{ marginLeft: '10px' }}>
                                <FilterOutlined style={{ fontSize: '20px' }}> </FilterOutlined>
                                Filter
                            </Button> */}
                        </div>
                    </div>

                </div>
                <div className="custom-line" />
                <div >
                    <Row gutter={24} className="detail-rows">
                        <Col span={40}>
                            <Card className='custom-card'
                                onClick={() => {
                                    history.push('/project-margin');
                                }}
                                style={{ background: '#ef314c', borderColor: '#ef314c' }}>
                                <div style={{ marginTop: '-10px' }} >
                                    <div >
                                        {"Project Margin"}
                                    </div>
                                    <div style={{ paddingTop: '5px' }}>
                                        <RightCircleOutlined></RightCircleOutlined>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
            }
            {isMasterModalVisible &&
                <MasteForm
                    isMasterModalVisible={isMasterModalVisible}
                    setIsMasterModalVisible={setIsMasterModalVisible}
                />
            }
        </div>
    );
};
const mapStateToProps = ({ }) => {
    return {
    }
}
const mapDispatchToProps = {
}
export default connect(mapStateToProps, mapDispatchToProps)(FinaceMapping);