//CRC-PM
export const FETCH_RAW_CRC_PM_LIST = '@@fetch-raw-crc-pm-List';
export const FETCH_RAW_CRC_PM_LIST_SUCCESS = '@@fetch-raw-crc-pm-List-success';
export const FETCH_RAW_CRC_PM_LIST_FAILURE = '@@fetch-raw-crc-pm-List-failure';

//CM-CRC
export const FETCH_RAW_CM_CRC_LIST = '@@fetch-raw-cm-crc-List';
export const FETCH_RAW_CM_CRC_LIST_SUCCESS = '@@fetch-raw-cm-crc-List-success';
export const FETCH_RAW_CM_CRC_LIST_FAILURE = '@@fetch-raw-cm-crc-List-failure';

//project summery
export const FETCH_RAW_PROJECT_SUMMERY_LIST = '@@fetch-raw-project-summery-List';
export const FETCH_RAW_PROJECT_SUMMERY_LIST_SUCCESS = '@@fetch-raw-project-summery-List-success';
export const FETCH_RAW_PROJECT_SUMMERY_LIST_FAILURE = '@@fetch-raw-project-summery-List-failure';
