/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable prefer-const */
/* eslint-disable comma-spacing */
/* eslint-disable quotes */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { ArrowLeftOutlined, RightCircleOutlined } from '@ant-design/icons';
import 'antd/dist/antd.dark.css';
import { Table, Tabs, notification, Tooltip, Typography, Select } from 'antd';
import { useHistory } from 'react-router-dom';
import Cji3Projs from './Cji3Projs';
import Cji3C406 from './Cji3C406';
import Cji3P8274 from './Cji3P8274';
import Cji3NoProj from './Cji3NoProj';

const { TabPane } = Tabs;
const { Title } = Typography;
const { Option } = Select;

const Checks3Tabs = () => {
    const history = useHistory();
    const [group, setGroup] = useState(false);
    const [currentSection, setCurrentSection] = useState('1');
    const [year, setYear] = useState([])
    const [selectedYear, setSelectedYear] = useState(null);

    const onChange = (key) => {
        setCurrentSection(key);
    };

    return (
        <div className="h-100 d-flex flex-column">
            <div className="d-flex mb-2 tab-content-title" style={{ justifyContent: 'space-between' }}>
                <div className='back_equip' >
                    <Tooltip title={'Back'} >
                        <ArrowLeftOutlined onClick={(e) => {
                            history.push('/project-margin');
                        }} />
                    </Tooltip>
                    <Title className="mb-0 mt-1 add-new-calibration" level={3} style={{ paddingLeft: '30px' }}>
                        Checks3
                    </Title>
                </div>
                <div style={{}}>
                    {/* <Select
                        className="maintenance-select"
                        placeholder="Select Year"
                        onChange={(value) => setSelectedYear(value)}
                        value={selectedYear}
                        size="medium"
                        showSearch
                        allowClear
                        defaultValue={year.length > 0 ? year[0] : undefined}
                        style={{ width: '150px', paddingLeft: '5px', paddingRight: '5px' }}
                    >
                        {year.length > 0 &&
                            year.map((item, i) => (
                                <Option key={i} value={item}>
                                    {item}
                                </Option>
                            ))}
                    </Select> */}

                </div>
            </div>
            <div className="flex-fill">
                <Tabs
                    className="custom-tab h-100"
                    onChange={onChange}
                >
                    <TabPane tab="YTD CJI3 Projs" key="1">
                        <Cji3Projs selectedYear={selectedYear} setSelectedYear={setSelectedYear} year={year} setYear={setYear} />
                    </TabPane>
                    <TabPane tab="YTD CJI3 C-4016" key="2">
                        <Cji3C406 selectedYear={selectedYear} setSelectedYear={setSelectedYear} year={year} setYear={setYear} />
                    </TabPane>
                    <TabPane tab="YTD CJI3 P-0008274" key="3">
                        <Cji3P8274 selectedYear={selectedYear} setSelectedYear={setSelectedYear} year={year} setYear={setYear} />
                    </TabPane>
                    <TabPane tab="YTD CJI3 NoProj" key="4">
                        <Cji3NoProj selectedYear={selectedYear} setSelectedYear={setSelectedYear} year={year} setYear={setYear} />
                    </TabPane>
                </Tabs>
            </div>
        </div>
    );
};

export default Checks3Tabs